import { module } from 'modujs';
import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import CustomBounce from 'gsap/CustomBounce';
import {html} from "../utils/environment";

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            wheel: {
                // container: 'scrollWords',
            },
            click: {
                wordsSingle: 'clickWords',
                openSubmit: 'openSubmit',
                container: 'closeSubmit',
                openGrid: 'toggleGrid',
                // container: 'closeSubmit'
            },
            mouseenter: {
                wordsSingle: 'mouseoverWords',
            },
            mouseleave: {
                wordsSingle: 'mouseleaveWords',
            },
        }

        gsap.registerPlugin(CustomEase, CustomBounce)
    }

    init() {
        this.loaded = false

        this.deltaMin = 1
        this.deltaMax = 90
        this.deltaY = this.deltaMin
        this.posY = 0

        this.tlPoints = null

        this.isHover = false
        this.hoverWord = null
        this.colorClass = ""

        this.animationRunning = false
        this.animNumber = 0
        this.tlAnimation = null

        this.submitOpen = false
        this.gridOpen = false

        this.lastScrollWheelTimestamp = Date.now()
        this.lastScrollWheelDelta = 0



        this.customEase = CustomEase.create("gna", "0.25, 0, 0, 1")


        this.createList()

        window.addEventListener('onwheel', this.scrollWords.bind(this))
        window.addEventListener('wheel', this.scrollWords.bind(this))

        document.querySelectorAll('.logos-container .logo').forEach(item=>{
            let xLogo = (window.innerWidth - item.scrollWidth) * Math.random()
            let yLogo = (window.innerHeight - item.scrollHeight) * Math.random()
            let rotate = 360 * Math.random()

            gsap.set(item, {x: xLogo, y: yLogo, rotate: rotate})
        })

        this.tick()
    }

    loaderWords(skip){
        if(!skip){
            let words = {'nb':0}
            let tlLoader = gsap.timeline()
            let iSpan = 0


            tlLoader
                .to('.loader-number', {autoAlpha: 1, duration: .6}, '.2')
                .to(words, {nb: document.querySelector(".words").childElementCount , ease: 'power1.inOut', duration: 1.8, onUpdate: ()=>{
                    document.querySelector('.loader-number').innerHTML = Math.ceil(words.nb)
                }}, '>1')
                .to('.loader span span', {
                    opacity: 1,
                    y: "0%",
                    x: "0%",
                    duration: .6,
                    stagger: 0.05,
                    ease: this.customEase,
                    onStart:function(){
                        document.querySelector('span.loader-number').classList.add('done')
                    }}, '>.6')
                .to('.loader p ', {autoAlpha: 0, ease: 'power2.out', duration: 0.8}, '>1.2')
                .set('.loader', {autoAlpha: 0})
                .to('.words li span:first-child',
                    {
                        y: "0%",
                        opacity: 1,
                        ease: this.customEase,
                        duration: .4,
                        stagger: {
                            each: 0.06,
                            onComplete: ()=>{
                                iSpan++
                                if(iSpan === 8)
                                    this.loaded = true
                            }
                        },
                    })
                .to('.more span',
                    {
                        opacity: 1,
                        y: "0%",
                        ease: this.customEase,
                        duration: .6
                    }, '< .4')
                .to(['header div', '.words-bottom > div'],
                    {
                        opacity: 1,
                        duration: .2,
                        ease: this.customEase
                    }, '< .4')
                .to('.words-bottom button',
                    {
                        opacity: 1,
                        x: 0,
                        duration: .6,
                        ease: "elastic.out(1, 0.6)"
                    }, '< .6')
            .to('.logo', {autoAlpha: 1, stagger: .4, duration: .1, ease: this.customEase}, '>.6')
        }else{
            this.loaded = true
            gsap.set('.loader', {autoAlpha: 0})
            gsap.set('.logo', {autoAlpha: 1})
            gsap.set(['header div', '.words-bottom button', '.words-bottom > div'], {autoAlpha: 1, x:0})
            gsap.set('.more span', {opacity: 1, y: 0})
            gsap.set('.words li span:first-child', {opacity: 1, y: 0})
        }
    }

    createList(){
        const that = this
        const getJSON = function(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'json';
            xhr.onload = function() {
                var status = xhr.status;
                if (status === 200) {
                    callback(null, xhr.response);
                } else {
                    callback(status, xhr.response);
                }
            };
            xhr.send();
        };

        // IF WEBPACK
        if(location.port === "3000"){
            document.querySelector('.wishes').dataset.wishes.split(',').forEach((item,i) => {
                let li = document.createElement('li')
                li.innerHTML =  '<span>' + item + '</span><span>loading<b>.</b><b>.</b><b>.</b></span>'
                li.dataset.src = document.querySelector('.wishes').dataset.src.split(',')[i]
                li.dataset.words = 'wordsSingle'
                if(html.classList.contains('is-touch-device') && i ===0){
                    li.classList.add('current')
                    let img = document.createElement('img')
                    img.src = document.querySelector('.wishes').dataset.src.split(',')[i]
                    document.querySelector('.img-container').appendChild(img)
                }
                document.querySelector('.words').appendChild(li);
            });
            document.querySelector('.words-bottom .count').innerHTML = document.querySelector(".words").childElementCount

            if(!html.classList.contains('is-touch-device')){
                document.querySelector(".words:last-of-type").after(document.querySelector(".words").cloneNode(true));
                document.querySelector(".words:last-of-type").after(document.querySelector(".words").cloneNode(true));

                setTimeout(()=>{
                    this.call('createGrid', null, 'Grid')
                },1000)
            }
            this.loaderWords()
        }else{
            getJSON('wishes-json.php',
                function(err, data) {
                    if (err !== null) {
                        console.log('Something went wrong: ' + err);
                    } else {
                        let i = 0
                        for (const [key, value] of Object.entries(data)) {

                            let li = document.createElement('li')
                            li.innerHTML = '<span>' + value['1'] + '</span><span>loading<b>.</b><b>.</b><b>.</b></span>'
                            li.dataset.src = value['0']
                            li.dataset.words = 'wordsSingle'
                            if(html.classList.contains('is-touch-device') && i ===0){
                                li.classList.add('current')
                                let img = document.createElement('img')
                                img.src = value['0']
                                document.querySelector('.img-container').appendChild(img)
                            }
                            document.querySelector('.words').appendChild(li);
                            i++
                        }
                    }
                    document.querySelector('.words-bottom .count').innerHTML = document.querySelector(".words").childElementCount

                    if(!html.classList.contains('is-touch-device')){
                        document.querySelector(".words:last-of-type").after(document.querySelector(".words").cloneNode(true));
                        document.querySelector(".words:last-of-type").after(document.querySelector(".words").cloneNode(true));
                        that.call('createGrid', null, 'Grid')
                    }
                    that.loaderWords()
                });
        }


    }

    scrollWords(e) {
        if(!this.gridOpen && !this.submitOpen){
            let isHuman = false
            const now = Date.now();
            const rapidSuccession = now - this.lastScrollWheelTimestamp < 100;
            if(Math.abs(e.deltaY - this.lastScrollWheelDelta) > 2 ){
                const otherDirection = (this.lastScrollWheelDelta > 0) !== (e.deltaY > 0);
                const speedDecrease = Math.abs(e.deltaY) <= Math.abs(this.lastScrollWheelDelta)
                isHuman = !rapidSuccession || otherDirection || !speedDecrease;
            }else {
                isHuman = !rapidSuccession
            }
            this.lastScrollWheelTimestamp = now;
            this.lastScrollWheelDelta = e.deltaY;

            if(isHuman){
                if(e.deltaY > this.deltaMax){
                    this.deltaY = this.deltaMax
                }else if(e.deltaY < -this.deltaMax){
                    this.deltaY = -this.deltaMax
                }else if(e.deltaY < this.deltaMin && e.deltaY>0){
                    this.deltaY = this.deltaMin
                }else if(e.deltaY > -this.deltaMin && e.deltaY<0){
                    this.deltaY = -this.deltaMin
                }else{
                    this.deltaY = e.deltaY
                }
                this.resetAnimation()
            }   
        }
    }

    clickWords(e) {
        if(this.submitOpen){
            this.closeSubmit()
        }else if(html.classList.contains('is-touch-device') && (e.target.tagName === "LI" || e.target.parentElement.tagName === "LI")){
            if(e.target.tagName === "LI"){
                this.currentElm = e.target
            }else{
                this.currentElm = e.target.parentElement
            }
            let img = document.createElement('img')
            img.src = this.currentElm.dataset.src
            document.querySelector('.img-container').innerHTML = ""
            document.querySelector('.img-container').appendChild(img)
            document.querySelectorAll('li.current').forEach(item =>{
                item.classList.remove('current')
            })
            this.currentElm.classList.add('current')
        }
    }

    mouseoverWords(e) {
        if((e.target.tagName === "LI" || e.target.parentElement.tagName === "LI") && !this.gridOpen && !this.submitOpen && this.loaded && !this.animationRunning && !html.classList.contains('is-touch-device')){
            if(e.target.tagName === "LI"){
                this.currentElm = e.target
            }else{
                this.currentElm = e.target.parentElement
            }
            this.indexElm = [... document.querySelectorAll('.words li')].indexOf(this.currentElm)

            document.querySelectorAll('li:nth-child('+([...this.currentElm.parentNode.children].indexOf(this.currentElm)+1)+')').forEach(item =>{
                item.classList.add('current')
            })

            let wordSpan = e.currentTarget.querySelector('span:nth-child(1)')
            let loadSpan = e.currentTarget.querySelector('span:nth-child(2)')
            let points = e.currentTarget.querySelectorAll('span b')

            this.isHover = true
            this.hoverWord = gsap.timeline({delay: 0})

            this.tlPoints = gsap.timeline({repeat: 1, paused: true, onComplete: ()=>{
                    if(this.isHover){
                        this.runAnimation()
                        this.animationRunning=true
                        this.currentElm.classList.add('playing')
                        this.hoverWord.reverse()

                        html.classList.add('is-animating')
                    }
                }})

            this.tlPoints
                .set(points, { opacity: 1})
                .to(points, {y: '0%', opacity : 1, ease: 'elastic.out(1, 0.8)', stagger: .03, duration: .35})
                // .to(points,{opacity: 0, duration: 0.05})

            this.hoverWord
                .set(points, {y: '60%'})
                .fromTo(wordSpan, {y: '0%'}, {y: '-100%', duration : .15, ease: this.customEase})
                .fromTo(loadSpan,{y: '100%'}, {y: '0%', duration : .15, ease: this.customEase, onComplete: ()=>{
                            this.tlPoints.play()
                        }},'<')

        }
    }

    mouseleaveWords(e) {
        if(e.currentTarget.tagName === "LI" && e.currentTarget.classList.contains('current') && !this.animationRunning && this.isHover && !this.submitOpen && this.loaded && !html.classList.contains('is-touch-device')){
            this.isHover = false
            document.querySelectorAll('li.current').forEach(item =>{
                item.classList.remove('current')
            })
            if(this.hoverWord && !this.animationRunning){
                this.hoverWord.reverse()
            }
            this.tlPoints.kill()
            this.resetAnimation()
        }
    }

    runAnimation() {
        let colorRand = Math.ceil(Math.random()*3)
        if(colorRand === 1){
            this.colorClass = "blue"
        }else if(colorRand === 2){
            this.colorClass = "green"
        }else if(colorRand === 3){
            this.colorClass = "yellow"
        }
        html.classList.add('is-'+this.colorClass)

        this.animNumber = Math.ceil(Math.random()*5)
        // this.animNumber = 1
        let img = document.createElement('img')
        img.src = this.currentElm.dataset.src
        document.querySelector('.animation-container').classList.add('anim-'+this.animNumber)

        switch (this.animNumber) {
            case 1:
                let max = 12,
                    iImg = 0
                for (let i = 0; i <= max; i++){
                    document.querySelector('.animation-container').appendChild(img.cloneNode(true))
                    gsap.set(
                        '.animation-container img:nth-child('+(i+1)+')',
                        {
                            rotate: ((max-i)*12)+'deg',
                            top: '50%',
                            left: '50%',
                            x: '-50%',
                            y: '-50%',
                        })
                }
                gsap.to('.animation-container img',
                    {
                        autoAlpha: 1,
                        duration: 0,
                        stagger: { // wrap advanced options in an object
                            each: 0.03,
                            ease: 'power1.out',
                        }
                    })
                gsap.to('.animation-container img',
                    {
                        autoAlpha: 0,
                        duration: 0,
                        delay: 2,
                        stagger: {
                            each: 0.03,
                            ease: 'power1.out',
                            onComplete: ()=>{
                                iImg++
                                if(iImg === max){
                                    this.resetAnimation()
                                }
                            }
                        },
                        onComplete: ()=>{
                            this.resetAnimation()
                        }
                    })
                break;
            case 2:
                img.onload= () => {
                    let ratio = img.width/img.height;

                    img.width = window.innerWidth / 5
                    img.height = img.width / ratio

                    let max2 = Math.ceil(window.innerHeight / img.height) * 5
                    for(let i = 0; i < max2; i++){
                        document.querySelector('.animation-container').appendChild(img.cloneNode(true))
                        gsap.set(
                            '.animation-container img:nth-child('+(i+1)+')',
                            {
                                top: img.height * Math.floor(i / 5),
                                right: img.width * (i % 5)
                            })
                        gsap.to('.animation-container img',
                            {
                                autoAlpha: 1,
                                duration: 0,
                                stagger: { // wrap advanced options in an object
                                    each: 1/max2,
                                    ease: "power1.out",
                                }
                            })
                        let iImg = 0;
                        gsap.to('.animation-container img',
                            {
                                autoAlpha: 0,
                                duration: 0,
                                delay: 2,
                                stagger: { // wrap advanced options in an object
                                    each: 1/max2,
                                    ease: "power1.out",
                                    onComplete: ()=>{
                                        iImg++
                                        if(iImg === max2){
                                            this.resetAnimation()
                                        }
                                    }
                                }
                            })
                    }
                    document.querySelector('.animation-container').appendChild(img.cloneNode(true))

                }
                break;
            case 3:
                document.querySelector('.animation-container').appendChild(img)
                let tlAnim3 = gsap.timeline()
                tlAnim3
                    .to('.animation-container img',{autoAlpha: 1,duration: .1})
                    .to('.more, .words li:not(.current)',{autoAlpha: .6,duration: .1}, '<')
                    .to('.animation-container img',{autoAlpha: 0, duration: .1}, '>3')
                    .to('.more, .words li:not(.current)',{autoAlpha: 1, duration: .1,
                        onComplete: ()=>{
                            this.resetAnimation()
                        }}, '<')
                break;
            case 4:
                CustomBounce.create("myBounce", {strength: 0.8, squashID: "myBounce-squash"});
                img.onload= () => {
                    document.querySelector('.animation-container').appendChild(img)
                    this.tlAnimation = gsap.timeline()
                    this.tlAnimation.set('.animation-container img',
                        {
                            autoAlpha: 1,
                            x: window.innerWidth - img.width/2,
                            y: -window.innerHeight + img.height/2,
                        })
                        .to('.animation-container img',
                        {
                            x: 0,
                            duration: 6,
                            ease: 'power1.out'
                        })
                        .to('.animation-container img',
                        {
                            y: 0,
                            duration: 6,
                            ease : 'myBounce',
                            onUpdate: ()=>{
                                document.querySelector('.animation-container').appendChild(img.cloneNode(true))
                            },
                            onComplete: ()=>{
                                this.resetAnimation()
                            }
                        }, '<')
                        .set('.animation-container img', {autoAlpha: 0}, '>2')

                }

                break;
            case 5:

                let tlImg = []
                img.onload= () => {
                    let iImg = 0
                    for(let i = 0; i < 12; i++){
                        document.querySelector('.animation-container').appendChild(img.cloneNode(true))
                        let rand = Math.random()
                        gsap.set(
                            '.animation-container img:nth-child('+(i+1)+')',
                            {
                                autoAlpha: 1,
                                top: Math.random() * (window.innerHeight-img.height),
                                right: -img.width * 1.5,
                                scale: .6 + rand*0.8,
                                zIndex: Math.ceil(rand*100)
                            })
                        tlImg.push(gsap.timeline({delay: Math.random()*3}))
                        tlImg[i].to('.animation-container img:nth-child('+(i+1)+')',
                            {
                                x: -window.innerWidth- img.width * 2.2,
                                duration: 6 + (4-rand) * rand,
                                ease: 'linear',
                                onComplete: ()=>{
                                    iImg++
                                    if(iImg === 12){
                                        this.resetAnimation()
                                    }
                                }
                            })
                    }
                }
                break;

            default:

        }
    }

    resetAnimation() {
        html.classList.remove('is-'+this.colorClass)
        if(this.animationRunning){
            this.animationRunning = false
            html.classList.remove('is-animating')
            document.querySelectorAll('li.current').forEach(item =>{
                item.classList.remove('current')
            })

            document.querySelector('.animation-container').classList.remove('anim-'+this.animNumber)
            gsap.to('.animation-container img', {autoAlpha: 0, duration: .1})

            document.querySelector('.animation-container').innerHTML = ""
            document.querySelectorAll('.playing').forEach(item =>{
                item.classList.remove('playing')
            })
            switch (this.animNumber) {
                case 3 :
                    gsap.set('.more, .words li', { autoAlpha: 1})
                    break;
                case 4 :
                    this.tlAnimation.kill()
                    this.tlAnimation= null
                    break;
            }
        }
    }

    openSubmit() {
        this.call('open', null, 'Submit')
        this.resetAnimation()
        this.submitOpen = true
    }

    closeSubmit() {
        this.call('close', null, 'Submit')

        this.submitOpen = false
    }

    toggleSubmit() {
        this.submitOpen = false
    }

    toggleGrid() {
        if(this.gridOpen){
            this.call('close', null, 'Grid')
        }else{
            this.call('open', null, 'Grid')
        }

        this.gridOpen = !this.gridOpen
    }

    tick(){
        if(!this.gridOpen){

            //MOVE WORDS TO DELTA MIN
            if(!html.classList.contains('is-touch-device')){
                if(this.deltaY > this.deltaMin)
                    this.deltaY -= (this.deltaY - this.deltaMin) * 0.02
                else if(this.deltaY < -this.deltaMin)
                    this.deltaY -= (this.deltaY + this.deltaMin) * 0.02

                this.posY -= 1 * this.deltaY / 5

                if(gsap.getProperty('.words-scroll', 'y') < -1 * document.querySelector('.words').offsetHeight)
                    this.posY = 0
                else if(gsap.getProperty('.words-scroll', 'y') > 0 )
                    this.posY = -1 * document.querySelector('.words').offsetHeight

                gsap.set('.words-scroll', {y: this.posY})
            }




            //MOVE LOGOS
            document.querySelectorAll('.logos-container .logo').forEach(item=>{

                let xLogo = gsap.getProperty(item, 'x') + parseInt(item.dataset.dirx) * parseFloat(item.dataset.speed)
                let yLogo = gsap.getProperty(item, 'y') + parseInt(item.dataset.diry) * parseFloat(item.dataset.speed)
                if(xLogo >= window.innerWidth - item.scrollWidth)
                    item.dataset.dirx = -1
                else if(xLogo <= 0){
                    item.dataset.dirx = 1
                }
                if(yLogo >= window.innerHeight - item.scrollHeight)
                    item.dataset.diry = -1
                else if(yLogo <= 0){
                    item.dataset.diry = 1
                }
                gsap.set(item, {x: xLogo, y: yLogo, rotation:"+="+parseFloat(item.dataset.rotation)})
            })


        }

        requestAnimationFrame(this.tick.bind(this))
    }
}
