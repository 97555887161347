import { module } from 'modujs';
import gsap from 'gsap';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                autocomplete: 'clickAutoComplete',
            },
            keyup: {
                inputSearch: 'searchResults'
            }
        }
    }

    init() {
        this.apikey = "5B6NEVBCULZE";
        const lmt = 5;

        // let psearch_term = 'test'
        // // using default locale of en_US
        // var autoc_url = "https://g.tenor.com/v1/autocomplete?key=" + this.apikey + "&q=" + psearch_term
        //     + "&limit=" + lmt;
        //
        // // send autocomplete request
        // this.httpGetAsync(autoc_url,this.tenorCallback_autocomplete);

    }

    searchResults(){
        let searchValue = this.$('inputSearch')[0].value

        let autocompleteUrl = "https://g.tenor.com/v1/autocomplete?key=" + this.apikey + "&q=" + searchValue + "&limit=4";
        this.httpGetAsync(autocompleteUrl,this.tenorCallback_autocomplete);

        let search_url = "https://g.tenor.com/v1/search?q=" + searchValue + "&key=" + this.apikey + "&limit=20";
        this.httpGetAsync(search_url,this.tenorCallback_search);
    }

    clickAutoComplete(e){
        if(e.target.tagName === 'LI'){
            let newValue = e.target.innerHTML
            this.$('inputSearch')[0].value = newValue
            this.searchResults()
        }
    }

    httpGetAsync(theUrl, callback) {
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function() {
            if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
                callback(xmlHttp.responseText);
            }
        }
        xmlHttp.open("GET", theUrl, true);
        xmlHttp.send(null);
        return;
    }


    tenorCallback_autocomplete(responsetext){
        const response_objects = JSON.parse(responsetext);
        const predicted_words = response_objects["results"];
        for(let i = 0; i<4; i++){
            let word = (predicted_words[i] !== undefined)? predicted_words[i] : ""
            document.querySelector(".autocomplete li:nth-child("+(i+1)+")").innerHTML = word;
        }
    }

    tenorCallback_search(responsetext){
        document.querySelector('.results').innerHTML=""
        const response_objects = JSON.parse(responsetext);
        const results = response_objects["results"];

        let that = this

        results.forEach( result =>{
            let img = document.createElement('img');
            img.src = result["media"][0]["tinygif"]["url"];
            document.querySelector('.results').appendChild(img);
        })


        // document.getElementById("preview_gif").src = results[0]["media"][0]["nanogif"]["url"];
        //
        // document.getElementById("share_gif").src = [0]["media"][0]["tinygif"]["url"];

        return;

    }

    tenorCallback_searchSuggestion(responsetext){
        var response_objects = JSON.parse(responsetext);

        predicted_words = response_objects["results"];

        document.getElementById("ac_1").innerHTML = predicted_words[0];
        document.getElementById("ac_2").innerHTML = predicted_words[1];
        document.getElementById("ac_3").innerHTML = predicted_words[2];
        document.getElementById("ac_4").innerHTML = predicted_words[3];
    }
}
