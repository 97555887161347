import modular from 'modujs';
import * as modules from './modules';
import globals from './globals';
import { html } from './utils/environment';
import gsap from 'gsap';

const app = new modular({
    modules: modules
});

window.onload = (event) => {
    const $style = document.getElementById('main-css');

    if ($style) {
        if ($style.isLoaded) {
            init();
        } else {
            $style.addEventListener('load', (event) => {
                init();
            });
        }
    } else {
        console.warn('The "main-css" stylesheet not found');
    }
};

function init() {
    globals();
    if(is_touch_device()){
        html.classList.add('is-touch-device');
        document.documentElement.style.setProperty('--app-height-init', window.innerHeight+'px')
        window.addEventListener('resize', appHeight)
        appHeight()
    }
    app.init(app);
    html.classList.add('is-loaded');
    html.classList.add('is-ready');
    html.classList.remove('is-loading');
    initMagnetic();

    //FPS
    // let prevTime = Date.now(),
    //     frames = 0;
    // requestAnimationFrame(function loop() {
    //     const time = Date.now();
    //     frames++;
    //     if (time > prevTime + 1000) {
    //         let fps = Math.round((frames * 1000) / (time - prevTime));
    //         prevTime = time;
    //         frames = 0;
    //         document.getElementById('fps').innerHTML = fps + " fps";
    //     }
    //     requestAnimationFrame(loop);
    // })
}

function initMagnetic() {
    if(is_touch_device() || window.innerWidth<=1024) return;
    Array.prototype.forEach.call(document.querySelectorAll('.magnetic'), function(el, i){
        var hover = false;
        var offsetHoverMax = el.getAttribute("offset-hover-max") || 1;
        var offsetHoverMin = el.getAttribute("offset-hover-min") || 0.75;

        var attachEventsListener = function() {
            window.addEventListener("mousemove", function(e) {
                var hoverArea = hover ? offsetHoverMax : offsetHoverMin;
                var cursor = { x: e.clientX, y: e.clientY + document.body.scrollTop }; // $(window).scrollTop()
                var width = el.offsetWidth;
                var height = el.offsetHeight;
                var offset = el.getBoundingClientRect();
                var elPos = { x: offset.left + width / 2, y: offset.top + height / 2 };
                var x = cursor.x - elPos.x;
                var y = cursor.y - elPos.y;
                var dist = Math.sqrt(x * x + y * y);
                var mutHover = false;
                if(dist < width * hoverArea) {
                    mutHover = true;
                    if(!hover) hover = true
                    onHover(x, y);
                }
                if(!mutHover && hover) { onLeave(); hover = false }
            });
        };

        var onHover = function(x, y) {
            gsap.to(el, { duration: 0.4, x: x * 0.8, y: y * 0.8, rotation: x * 0.05, ease: 'power2.out' });
        };
        var onLeave = function() {
            gsap.to(el, { duration: 0.7, x: 0, y: 0, scale: 1, rotation: 0, ease: 'elastic.out' });
        };

        attachEventsListener();
    });
}
function is_touch_device() {
    return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
}
function appHeight() {
    document.documentElement.style.setProperty('--app-height', window.innerHeight+'px')
}