import { module } from 'modujs';
import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import {html} from "../utils/environment";

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                autocomplete: 'clickAutoComplete',
                toGif: 'toGif',
                toSubmit: 'toSubmit',
                gif: 'selectedGif',
                submitButton: 'submitWish'
            },
            keyup: {
                inputSearch: 'searchResults',
                inputWish : 'wishInput'
            }
        }
    }

    init() {
        this.apikey = "5B6NEVBCULZE";
        this.hasWish = false
        this.hasGif = false
        this.isOpen = false
        this.submittedIsOpen = false
        this.posX = 0
        this.monEase = CustomEase.create("gna", "0.25, 0, 0, 1")

    }

    open(){
        if(!this.isOpen){
            this.isOpen = true
            gsap.set('.submit', {display: "block"})
            gsap.set('.submit-bg, .submit-form', {y: "-60%"})
            gsap.to('.submit', {autoAlpha: 1, duration: .2})
            gsap.to('.submit-bg, .submit-form', {y: "0%", ease: "elastic.out(1, 0.4)", duration: .8})
            if(html.classList.contains('is-touch-device')){
                gsap.to('.overlay-mobile', {autoAlpha: .4, ease: "power3.out"})
            }
        }
    }

    close() {
        if(this.isOpen){
            this.isOpen = false
            let tlSubmit = gsap.timeline()
            if(html.classList.contains('is-touch-device')){
                tlSubmit
                    .to('.overlay-mobile', {autoAlpha: 0, duration: .6, ease: "power3.out"})
            }
            tlSubmit
                .to('.submit-bg, .submit-form', {y: "-60%", ease: "power3.out", duration: .6}, '<')
                .to('.submit', {autoAlpha: 0, ease: "power3.out", duration: .5}, '<')
                .set('.submit', {display: "block"})
        }
    }

    wishInput(e){
        if(e.target.value !== ""){
            this.hasWish = true
            if(this.hasGif){
                document.querySelector('.submit button[type="submit"]').disabled = false
            }
        }else{
            this.hasWish = false
            document.querySelector('.submit button[type="submit"]').disabled = true
        }
    }

    toGif(){
        document.querySelector('.submit .step-1').style.display = 'none'
        document.querySelector('.submit .step-2').style.display = 'block'

        this.searchResults()
    }

    toSubmit(){
        document.querySelector('.submit .step-1').style.display = 'block'
        document.querySelector('.submit .step-2').style.display = 'none'
    }

    searchResults(){
        let searchValue = this.$('inputSearch')[0].value

        let autocompleteUrl = "https://g.tenor.com/v1/autocomplete?key=" + this.apikey + "&q=" + searchValue + "&limit=4";
        this.httpGetAsync(autocompleteUrl,this.tenorCallback_autocomplete);

        let search_url = "https://g.tenor.com/v1/search?q=" + searchValue + "&key=" + this.apikey + "&limit=20";
        this.httpGetAsync(search_url,this.tenorCallback_search);
    }

    selectedGif(e){
        this.hasGif = true
        const img = new Image();
        img.src = e.target.src

        document.querySelector('.submit-success').dataset.src = e.target.src;
        img.onload= function() {
            document.querySelector('.submit-success').dataset.ratio = this.width/this.height;
        }

        document.querySelector('.selected-gif__img').innerHTML = "";
        document.querySelector('.selected-gif__img').appendChild(img);

        document.querySelector('.toGif').style.display = 'none'
        document.querySelector('.selected-gif').style.display = 'flex'

        if(this.hasWish){
            document.querySelector('.submit button[type="submit"]').disabled = false
        }

        this.toSubmit()
    }

    submitWish(e){
        if(!e.target.disabled){
            this.sendData()
            let tlSubmit = gsap.timeline()
            tlSubmit
                .set('.overlay-mobile', {autoAlpha: 0})
                .set('header > div:first-child', {zIndex: 0})
                .to('.submit-form', {autoAlpha: 0})
                .to('.submit', {height: '100vh', ease: this.monEase})
                .to('.submit-bg', {borderRadius: 0}, '<')
                .set('.submit-form', {display: 'none'})
                .to('.submit', {width: '100vw', ease: this.monEase, onStart: ()=>{
                        html.classList.add('is-submit-open')
                    }})
                .set('.submit-success', {display: 'block', autoAlpha: 0})
                .to('.submit-success', {autoAlpha: 1})
                .to('.submit', {autoAlpha: 0,
                    onStart : ()=>{
                        html.classList.remove('is-submit-open')
                    },
                    onComplete : ()=>{
                        this.isOpen = false
                        this.submittedIsOpen = false
                        this.resetSubmit()
                    }}, '>3')
                .set('header > div:first-child', {zIndex: 5})

            let heightImg = document.documentElement.clientHeight * 0.22
            let widthImg = heightImg * document.querySelector('.submit-success').dataset.ratio + document.documentElement.clientHeight * 0.02

            const nbImg = Math.ceil(document.documentElement.clientWidth / widthImg) + 1

            for(let i = 0; i < nbImg; i++){
                const img = new Image();
                img.src = document.querySelector('.submit-success').dataset.src

                document.querySelectorAll('.submit-success__marquee-wrap')[0].appendChild(img);
                document.querySelectorAll('.submit-success__marquee-wrap')[1].appendChild(img.cloneNode(true));
            }
            this.submittedIsOpen = true
            this.tick()
        }

    }

    resetSubmit(){
        gsap.set('.submit-form', {autoAlpha: 1, display: 'block'})
        gsap.set('.submit', {height: 'unset', width: 540})
        gsap.set('.submit-bg', {borderRadius: 10, display: 'block'})
        gsap.set('.submit-success', {display: 'none'})
        document.querySelector('.step-1 input').value = ""
        document.querySelector('.step-2 input').value = ""
        document.querySelectorAll('.submit-success__marquee-wrap')[0].innerHTML = ""
        document.querySelectorAll('.submit-success__marquee-wrap')[1].innerHTML = ""
        document.querySelector('.toGif').style.display = 'flex'
        document.querySelector('.selected-gif').style.display = 'none'
        document.querySelector('.selected-gif__img').innerHTML = ""
        document.querySelector('.submit button[type="submit"]').disabled = true
        this.call('toggleSubmit', null, 'Words')
        this.hasWish = false
        this.hasGif = false
    }

    clickAutoComplete(e){
        if(e.target.tagName === 'LI'){
            let newValue = e.target.innerHTML
            this.$('inputSearch')[0].value = newValue
            this.searchResults()
        }
    }

    httpGetAsync(theUrl, callback) {
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function() {
            if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
                callback(xmlHttp.responseText);
            }
        }
        xmlHttp.open("GET", theUrl, true);
        xmlHttp.send(null);
        return;
    }

    tenorCallback_autocomplete(responsetext){
        const response_objects = JSON.parse(responsetext);
        const predicted_words = response_objects["results"];
        for(let i = 0; i<4; i++){
            let word = (predicted_words[i] !== undefined)? predicted_words[i] : ""
            document.querySelector(".autocomplete li:nth-child("+(i+1)+")").innerHTML = word;
        }
    }

    tenorCallback_search(responsetext){
        document.querySelector('.results').innerHTML=""
        const response_objects = JSON.parse(responsetext);
        const results = response_objects["results"];

        let that = this

        let imagesLoaded = 0
        results.forEach( result =>{
            let img = new Image();
            img.onload = function() {
                imagesLoaded++
                if (imagesLoaded === results.length) {
                    let elem = document.querySelector('.results');
                    let msnry = new Masonry( elem, {
                        // options
                        itemSelector: 'img',
                        columnWidth: 'img',
                        gutter: 5
                    });
                }
            }
            img.src = result["media"][0]["tinygif"]["url"]
            img.dataset.submit = "gif"
            document.querySelector('.results').appendChild(img);
        })

        return;
    }

    tenorCallback_searchSuggestion(responsetext){
        var response_objects = JSON.parse(responsetext);

        predicted_words = response_objects["results"];

        document.getElementById("ac_1").innerHTML = predicted_words[0];
        document.getElementById("ac_2").innerHTML = predicted_words[1];
        document.getElementById("ac_3").innerHTML = predicted_words[2];
        document.getElementById("ac_4").innerHTML = predicted_words[3];
    }

    tick(){
        if(this.submittedIsOpen){
            this.posX -= 1

            if(gsap.getProperty('.submit-success__marquee-wrap', 'x') < -1 * (document.querySelector('.submit-success__marquee-wrap img').offsetWidth + document.documentElement.clientHeight * 0.02))
                this.posX = 0

            gsap.set('.submit-success__marquee:nth-child(1) .submit-success__marquee-wrap', {x: this.posX})
            gsap.set('.submit-success__marquee:nth-child(3) .submit-success__marquee-wrap', {x: -this.posX})

            requestAnimationFrame(this.tick.bind(this))
        }

    }

    sendData() {
        let XHR = new XMLHttpRequest();
        let urlEncodedData = "";
        let urlEncodedDataPairs = [];


        urlEncodedDataPairs.push("gif="+encodeURIComponent(document.querySelector('.submit-success').dataset.src))
        urlEncodedDataPairs.push("word="+encodeURIComponent(document.querySelector('.step-1 input').value))


        urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');


        XHR.addEventListener('load', function(event) {
            // console.log('Ouais ! Données envoyées et réponse chargée.');
            // console.log(event.target.response);
        });

        XHR.addEventListener('error', function(event) {
            // console.log('Oups! Quelque chose s\'est mal passé.');
        });

        XHR.open('POST', 'submit.php');

        XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

        XHR.send(urlEncodedData);

    }
}
