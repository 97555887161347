import { module } from 'modujs';
import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import { html } from '../utils/environment'

export default class extends module {
    constructor(m) {
        super(m);


        this.events = {
            mousemove: {
                gridContainer: 'moveGrid',
            },
            click: {
                gridContainer: 'toggleGif',
                gridClose : 'closeBtn'
            },
            wheel: {
                gridContainer: 'zoomGrid',
            },
        }

        // const sourceImage = this.el.querySelector('.grid_img'),
        //     imgContainer = this.el.querySelector('.grid_wrapper');
        // for(let i = 1; i < 336; i++){
        //     const imgClone = sourceImage.cloneNode(true)
        //     imgClone.querySelector('img').src = imgClone.querySelector('img').src+"?random="+Math.random()
        //     imgContainer.appendChild(imgClone);
        // }

    }

    init() {
        this.lastX = false
        this.lastY = false
        this.isZoomed = true
        this.isZooming = false
        this.tweenImg = null
        this.isOpen = false
        this.beenOpen = false
        this.monEase = CustomEase.create("gna", "0.25, 0, 0, 1")

        this.hiddenImageIndexes = [315,294,273,252,231,210,0,21,42,63,84,105,126,147,168,189,5,26,47,68,89,110,131,152,173,194,215,236,257,278,299,320,211,212,213,214,216,217,218,219,220,221,222,223,224,225,226,227,228,229,230,43,44,45,46,48,50,49,51,52,53,54,55,56,57,58,59,60,61,62,73,31,10,94,115,136,157,178,199,204,183,162,141,120,99,78,83,104,125,146,167,188,209,1,2,22,23,3,4,6,7,8,9,11,12,13,14,15,16,17,18,19,20,24,25,27,29,30,28,32,33,34,35,36,37,38,39,40,41,232,233,234,235,237,238,239,240,241,242,243,244,245,246,247,248,249,250,251,253,254,255,256,258,259,260,261,262,264,263,265,266,267,268,269,270,271,272,274,275,276,277,279,280,281,282,283,284,285,286,287,289,288,290,291,292,293,314,313,312,333,334,335,311,332,331,310,309,330,329,308,307,328,327,306,305,326,325,304,303,324,323,302,301,322,321,300,298,319,318,297,296,317,316,295,64,67,172,151,171,106,86,87,108,129,128,107,127,149,148,169,69,195,198,72,92,91,112,113,134,133,154,155,176,175,74,77,97,96,117,118,139,138,137,158,159,179,182,161,181,79,82,102,101,122,123,144,143,164,121,142,116,163,184,186,187,166]


    }

    createGrid(){
        const gridWrapper = this.el.querySelector('.grid-wrapper')


        for( let i = 0; i < 335; i++){
            let containerImg = document.createElement('div')
            containerImg.classList.add('grid-img')

            let img = new Image();
            let iCurrent = i % document.querySelectorAll('.words li').length
            img.src = document.querySelectorAll('.words li')[iCurrent].dataset.src

            containerImg.appendChild(img);
            gridWrapper.appendChild(containerImg);

        }

        this.hiddenImages = this.hiddenImageIndexes.map(x => document.querySelectorAll('.grid-wrapper img')[x]);
    }
    open(){
        html.classList.add('is-grid-open')
        gsap.set('.grid-container', {display: "block"})
        gsap.to('.grid-container', {autoAlpha: 1, onComplete: ()=>{
                this.isOpen = true
                if(!this.beenOpen)
                    this.firstOpen()
            }})
    }
    firstOpen(){
        // this.tweenImg = gsap.to(this.el.querySelectorAll('img'), {opacity: 1, duration: 0, stagger: {each: 0.01, from: "random"} })

        this.wrapperWidth = document.querySelector('.grid-wrapper').getBoundingClientRect().width
        this.wrapperHeight = document.querySelector('.grid-wrapper').getBoundingClientRect().height
        this.tick()
    }

    close() {
        html.classList.remove('is-grid-open')
        let tlSubmit = gsap.timeline()
        tlSubmit
            .to('.grid-container', {autoAlpha: 0, duration: .4})
            .set('.grid-container', {display: "block", onComplete: ()=>{
                    this.isOpen = false
                    if (!this.isZoomed){
                        this.isZoomed = !this.isZoomed
                        gsap.set('.grid-wrapper', {x: this.lastX, y: this.lastY, scale: 3})
                        gsap.set('.grid-container > p span', {y: "100%" , duration: .6, ease: "power4.in"})
                        gsap.set(this.hiddenImages, {autoAlpha: 1})
                        gsap.set('.grid-bottom p span:last-child', {opacity:0, display: 'none'})
                        gsap.set('.grid-bottom p span:first-child', {display: 'inline-block', opacity:1})
                        gsap.set('.grid-bottom p', {width: 160})
                    }
                }})
    }
    closeBtn(){
        this.call('toggleGrid', null, 'Words')
    }
    hideImg(e){
        if(e.target.tagName === 'IMG'){
            this.hiddenImageIndexes.push([...e.target.parentElement.parentElement.children].indexOf(e.target.parentElement))
            gsap.to(e.target, {opacity: 0})

            console.log(this.hiddenImageIndexes)
        }
    }
    toggleGif(e){
        console.log(e.target)
    }
    moveGrid(e) {
        let percentX = (e.clientX / window.innerWidth - 0.5) * 2,
            percentY = (e.clientY / window.innerHeight - 0.5) * 2

        this.mouseX = (window.innerWidth - this.wrapperWidth)/2*percentX
        this.mouseY = (window.innerHeight - this.wrapperHeight)/2*percentY

    }
    zoomGrid(e) {

        if(!this.isZooming){

            if(!this.isZoomed && e.deltaY < 0){

                this.isZooming = true
                if(this.tweenImg){
                    this.tweenImg.kill()
                }

                this.tweenImg = gsap.to(
                    this.hiddenImages,
                    {
                        autoAlpha: 1,
                        duration: 0.1,
                        stagger:
                            {
                                each: 0.002,
                                from: "random"
                            },
                        delay: .6,
                        onComplete: ()=>{
                            this.isZoomed = true
                            this.isZooming = false
                        }
                    })

                let tlZoom = gsap.timeline()
                tlZoom
                    .to('.grid-container>p span', {y: "100%" , duration: .6, ease: "power4.in"})
                    .to('.grid-wrapper', {x: this.lastX, y: this.lastY, scale: 3, duration: .6})

                    .to('.grid-bottom p span:last-child', {opacity:0 , duration: .2, ease: "power4.out"}, '<')
                    .to('.grid-bottom p', {width: 160 , duration: .2, ease: "power1.in"}, '<')
                    .set('.grid-bottom p span:last-child', {display: 'none'})
                    .set('.grid-bottom p span:first-child', {display: 'inline-block', opacity:0})
                    .to('.grid-bottom p span:first-child', {opacity:1, duration: .4, ease: "power4.in"})

            }else if(this.isZoomed && e.deltaY > 0){

                this.isZooming = true
                if(this.tweenImg){
                    this.tweenImg.kill()
                }

                this.isZoomed = false

                this.tweenImg = gsap.to(
                    this.hiddenImages,
                    {
                        autoAlpha: 0,
                        duration: 0.1,
                        stagger:
                            {
                                each: 0.003,
                                from: "random"
                            },
                        onComplete: ()=>{
                            this.isZooming = false
                        }
                    })

                let widthSpan = document.querySelector('.grid-bottom p span:last-child').scrollWidth

                let tlZoom = gsap.timeline()
                tlZoom
                    .to('.grid-wrapper', {scale: 1, x: 0, y: 0, duration: .8, ease: "power3.inOut"})
                    .to('.grid-bottom p span:first-child', {opacity:0 , duration: .6, ease: "power4.out"}, '<')
                    .set('.grid-bottom p span:first-child', {display: 'none'})
                    .set('.grid-bottom p span:last-child', {display: 'inline-block', opacity:0})

                    .to('.grid-container > p span', {y: "0%" , duration: .5, ease: this.monEase, stagger: .16})

                    .to('.grid-bottom p', {width: 66 , duration: .2, ease: "power1.in"}, '<')
                    .to('.grid-bottom p span:last-child', {opacity:1, duration: .4, ease: "power4.in"}, '<')

            }

        }
    }
    tick(){
        if(this.isOpen){
            if(!this.lastX){
                this.lastX = 0
                this.lastY = 0
            }


            if(this.isZoomed){

                this.lastX += (this.mouseX - this.lastX) * 0.02

                this.lastY += (this.mouseY - this.lastY) * 0.02

                gsap.set('.grid-wrapper', {x: this.lastX, y: this.lastY})

            }else if(!this.isZooming){

                this.lastX = this.mouseX

                this.lastY = this.mouseY

            }



            requestAnimationFrame(this.tick.bind(this))
        }
    }
}
